import React from 'react';

import { Colors, HexColors } from 'constants/Colors';

interface Props {
  color: Colors;
  className?: string;
}

const VenturesLogo: React.FC<Props> = ({ color, className = '' }) => {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 245 40"
      fill="seagreen"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0,.34H3.48V17.5H0Z" />
      <path d="M6.14.34H9.67l5.71,9.57c.56.94,1.2,2.4,1.2,2.4h.05s-.09-1.77-.09-3v-9H20V17.5H16.61L10.75,8C10.2,7.1,9.53,5.64,9.53,5.64h0s.1,1.8.1,3V17.5H6.14Z" />
      <path d="M22.61.34H34.9v3H26.09V7.42h7.46v3H26.09V17.5H22.61Z" />
      <path d="M36.89.34h3.48V17.5H36.89Z" />
      <path d="M43,.34h3.53l5.71,9.57c.55.94,1.2,2.4,1.2,2.4h.05s-.1-1.77-.1-3v-9h3.44V17.5H53.5L47.64,8c-.55-.92-1.22-2.38-1.22-2.38h-.05s.09,1.8.09,3V17.5H43Z" />
      <path d="M59.52.34H63V17.5H59.52Z" />
      <path d="M64.77.34H78.58V3.26H73.42V17.5H69.93V3.26H64.77Z" />
      <path d="M80.33.34H93V3.26H83.81V7.1h8V10h-8v4.63H93V17.5H80.33Z" />
      <path d="M95.18.34h7.54a6,6,0,0,1,4.49,1.6,5.51,5.51,0,0,1,1.53,3.92c0,3.24-2.13,5.42-5.73,5.42H98.78V17.5h-3.6Zm3.6,8.11h3.6c1.83,0,2.79-1,2.79-2.57s-1-2.52-2.74-2.52H98.78Z" />
      <path d="M110.47.34H114V14.59H122V17.5H110.47Z" />
      <path d="M128.73.34h3.6l6,17.16h-3.62l-1.15-3.65h-6.2l-1.12,3.65h-3.53Zm-.5,10.85h4.54l-1.54-4.95c-.29-.91-.7-2.64-.7-2.64h0s-.44,1.73-.72,2.64Z" />
      <path d="M138.12,9c0-5.09,3.17-9,8.23-9,4.22,0,6.72,2.52,7.08,5.78H150a3.38,3.38,0,0,0-3.62-2.85c-3.17,0-4.68,2.64-4.68,6.05s1.82,6,4.7,6a3.46,3.46,0,0,0,3.7-3h3.41a6.1,6.1,0,0,1-1.9,4.05,7.22,7.22,0,0,1-5.21,1.83C141.55,17.86,138.12,14.14,138.12,9Z" />
      <path d="M155.28.34H167.9V3.26h-9.14V7.1h8V10h-8v4.63H168V17.5H155.28Z" />
      <path d="M169.25,12.19h3.4c.24,2.07,1.42,2.88,3.87,2.88,1.77,0,3.33-.62,3.33-2.2s-1.63-2-4.24-2.62c-3.08-.7-5.88-1.51-5.88-5.09,0-3.38,2.76-5.14,6.57-5.14s6.36,1.9,6.63,5.36h-3.34c-.19-1.73-1.54-2.6-3.31-2.6s-3.1.8-3.1,2c0,1.39,1.2,1.8,3.75,2.35,3.52.77,6.4,1.61,6.4,5.31,0,3.48-2.8,5.42-6.67,5.42C172,17.88,169.36,15.82,169.25,12.19Z" />
    </svg>
  );
};

export default VenturesLogo;
