import React from 'react';

import { Colors, HexColors } from 'constants/Colors';

interface Props {
  color: Colors;
  className?: string;
}

const MenuIcon: React.FC<Props> = ({ color, className = '' }) => {
  return (
    <svg
      className={className}
      width="70"
      height="120"
      viewBox="0 10 140 80"
      transform="rotate(90)"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.965,115.728c-2.058,0-4.13-0.665-5.872-2.038l-14.141-11.141C-0.21,93.755-2.892,76.973,4.974,65.139l16.506-24.837
                      c2.444-3.678,1.559-9.319-1.896-12.07L5.888,17.327C1.783,14.059,1.105,8.082,4.374,3.978c3.269-4.105,9.244-4.783,13.35-1.514
                      l13.695,10.905c11.096,8.836,13.735,25.636,5.885,37.449L20.798,75.655c-2.432,3.659-1.537,9.252,1.912,11.971l14.141,11.141
                      c4.121,3.246,4.83,9.22,1.583,13.341C36.56,114.486,33.776,115.728,30.965,115.728z"
        fill={HexColors['nutella']}
      />
      <path
        d="M75.161,115.728c-2.058,0-4.13-0.665-5.872-2.038l-14.141-11.141c-11.162-8.794-13.844-25.576-5.979-37.41l16.506-24.837
                      c2.444-3.678,1.559-9.319-1.896-12.07L50.084,17.327c-4.104-3.268-4.782-9.245-1.514-13.349c3.269-4.105,9.244-4.783,13.35-1.514
                      l13.695,10.905c11.096,8.836,13.735,25.636,5.885,37.449L64.994,75.655c-2.432,3.659-1.537,9.252,1.912,11.971l14.141,11.141
                      c4.121,3.246,4.83,9.22,1.583,13.341C80.756,114.486,77.973,115.728,75.161,115.728z"
        fill={HexColors['nutella']}
      />
      <path
        d="M119.357,115.728c-2.058,0-4.13-0.665-5.872-2.038l-14.141-11.141c-11.162-8.794-13.844-25.576-5.979-37.41l16.506-24.837
                      c2.444-3.678,1.559-9.319-1.896-12.07L94.28,17.327c-4.104-3.268-4.782-9.245-1.514-13.349c3.269-4.105,9.244-4.783,13.35-1.514
                      l13.695,10.905c11.096,8.836,13.735,25.636,5.885,37.449L109.19,75.655c-2.432,3.659-1.537,9.252,1.912,11.971l14.141,11.141
                      c4.121,3.246,4.83,9.22,1.583,13.341C124.952,114.486,122.169,115.728,119.357,115.728z"
        fill={HexColors['nutella']}
      />
    </svg>
  );
};

export default MenuIcon;
