export type Colors =
  | 'transparent'
  | 'white'
  | 'chalk'
  | 'lilac-lightest'
  | 'lilac-lighter'
  | 'lilac'
  | 'lilac-darker'
  | 'lilac-darkest'
  | 'lilac-very-dark'
  | 'mulberry'
  | 'nutella'
  | 'nutella-darker'
  | 'charcoal'
  | 'black-lighter'
  | 'black';

export enum HexColors {
  'transparent' = 'rgba(255, 255, 255, 0)',
  'white' = '#fecea4',
  'chalk' = '#fff3e3',
  'lilac-lightest' = '#f2dedc',
  'lilac-lighter' = '#fecea4',
  'lilac' = '#ffb2b2',
  'lilac-darker' = '#fdfc71',
  'lilac-darkest' = '#0055dd',
  'lilac-very-dark' = '#2d77fc',
  'mulberry' = '#000000',
  'nutella' = 'rgb(255,84,22)',
  'nutella-darker' = '#ffb34d',
  'charcoal' = '#242424',
  'black-lighter' = 'rgba(0, 0, 0, 0.5)',
  'black' = '#000000',
}

export const PortableTextColors = {
  CHALK: '#fafaf6',
  LILAC: '#cdd4fd',
  LILAC_DARKEST: '#8696f0',
  MULBERRY: '#682f4e',
  NUTELLA: 'rgb(255,84,22)',
  CHARCOAL: '#242424',
};
