export default `{
  'founderPortrait': {
    'src': founderPortrait.asset->url,
    'caption': founderPortrait.caption,
    'alt': founderPortrait.alt
  },
  'linkedIn': linkedIn.link,
  'instagram': instagram.link,
  'twitter': twitter.link,
  ...
}`;
